
/*************************
* ZÁHLAVÍ
*************************/

 
/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: $zahlavipozadi;
	color: $zahlavitext;

	@include link {
		color: $zahlavitext;
	}
}

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
	padding-top: 14px;
}

#znak {
	margin: 0;
	max-width: 77px;
	height: auto;
	display: none;
}

.hlava-cols {
	.hlava-col {
		margin: 10px 4% 20px 4%;

		+ .hlava-col {
			text-align: center;		
		}
	}

	@include bp("> 600px") {
		//display: table;
		//table-layout: fixed;
		width: 100%;
		
		&::after {
			content: "";
			display: table;
			width: 100%;
			height: 0;
			clear: both;
		}

		.hlava-col {
			//display: table-cell;
			vertical-align: top;
			float: left;
			margin: 0;
			padding: 0 0 20px 0;

			&:first-child {
				margin-top: -1em;
			}

			+ .hlava-col {
				float: right;
				text-align: right;	
			}
		}
	}
}


#nazev {
	text-align: center;
	position: relative;

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		img {
			font-size: rem(16px);
			line-height: 1;
		}

		&::after {
			content: "";
			@extend %fill;
		}
	}
}

@include bp("> 600px") {
	// #znak,
	// #nazev,
	// #nazev a,
	// h1.cvi {
	// 	float: left;
	// }

	#znak {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}

	#nazev {
		text-align: left;
	}
}


/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obrazek {
	display: none;
}

#obalobrazek {
	@extend %sf;
}

@include bp("> 600px") {
	#obalobrazek {
		display: block;
		clear: both;
	}

	.wrapper-uvod::after {
		background: #333333 url(images/bg-main-bottom.jpg) no-repeat 50% 100%;
	}

	#obrazek {
		position: relative;
		display: block;
		background: #333333 url(images/bg-main.jpg) no-repeat 50% 50%;
		background-size: cover;

		
		#blender {
			z-index: 1;
			position: relative;
			height: 0;
			top: 15px;
			padding-bottom: 40%;

			div[id] {
				@include fill;
				width: auto !important;
				height: auto !important;
				background-size: cover !important;
				overflow: visible;
			}

			.prolinani-e {
				display: table;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
			}

			.prolinani-e-spany {
				padding-left: 40px;

				$gradient-opacity-sides: .3;
				$gradient-opacity-center: .5;

				background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,$gradient-opacity-sides) 10%,rgba(0,0,0,$gradient-opacity-center) 50%,rgba(0,0,0,$gradient-opacity-sides) 90%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}

			.prolinani-e-obrazek,
			.prolinani-e-texty {
				display: table-cell;
				vertical-align: middle;

			}

			.prolinani-e-texty {
				color: #ffffff;
                font-family: $font;
                visibility: hidden;
			}

			.prolinani-e-nazev,
			.prolinani-e-popisek {
				display: block;
			}

			.prolinani-e-nazev {
				border-top: 4px #ffffff solid;
				font-size: rem(40px);
				padding: 6px 0 8px 0;
				line-height: .9;
				text-shadow: 1px 1px 1px rgba(#000000, .5);

				&:only-child {
					padding-bottom: 12px;
					border-bottom: 4px #ffffff solid;
				}

				@include bp("> 1024px") {
					font-size: rem(70px);
				}
			}

			.prolinani-e-popisek {
				line-height: 1.1;
				font-size: rem(30px);
				text-transform: uppercase;
				border-bottom: 4px #ffffff solid;
				padding: 6px 0 8px 0;
			}

			.prolinani-e-obrazek {
				box-sizing: border-box;
				width: 40%!important;
				height: 0;
				border-radius: 50%;
				box-shadow: 0 2px 4px rgba(#000000, .7);
				overflow: hidden;
				border: 7px white solid;
				padding-bottom: 37.88%;
				position: relative;
				@include bp("> 1024px") {
					border: 10px white solid;
                }
                @include bp("> 1280px") {
					width: 50%!important;
				}

				a,
				span {
					@include fill;
					background-size: cover;
				}
			}
/*
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: auto;
			}*/
		}
	}

	#administraceobrazky {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	// #obrlinky {
	// 	position: absolute;
	// 	z-index: 2;
	// 	bottom: 14px;
	// 	right: 25px;

	// 	ul {
	// 		margin: 0;
	// 		padding: 0;
	// 		list-style-type: none;

	// 		li {
	// 			display: inline;
	// 			padding: 0;
	// 			margin: 0 0 0 14px;
	// 		}
	// 	}

	// 	a {
	// 		display: inline-block;
	// 		font-size: 0;
	// 		line-height: 0;
	// 		width: 17px;
	// 		height: 17px;
	// 		border-radius: 100px;
	// 		background-color: #ffffff;
	// 		box-shadow: 1px 1px 0 rgba(#000000, .85) inset;

	// 		&.imagesBlender_link_active {
	// 			background-color: red;
	// 		}
	// 	}
	// }
}


#lista {
	@extend %sf;
	text-align: right;
	padding: 0;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: 1rem;
    }

    @supports (-ms-ime-align:auto) {
        margin-top: 1rem;
    }
     
     

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
